import { FC } from "react";

import '../scss/WhiteCard.scss';

interface WhiteCardProps {
    title: string;
    questions: {
        question: string;
        answer: string;
    }[];
}

const WhiteCard: FC<WhiteCardProps> = ({ title, questions }) => {
    return (
        <div className="white_card_container">
            <div className="white_card_title">
                {title}
            </div>
            <div>
                {questions.map((question, index) => (
                    <div key={index} className="question_and_answer_container">
                        <div className="question">{question.question}</div>
                        <div className="answer" >{question.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WhiteCard;