import { FC, useState } from 'react';

import { MONTHS, YEARS } from "../common/consts/dates";
import { FilterContext, SelectItem } from '../common/contexts/FilterContext';



const FilterContextProvider: FC = ({ children }) => {
    const [years, setYear] = useState<SelectItem[]>([YEARS[0]]);
    const [months, setMonth] = useState<SelectItem[]>([MONTHS[0]]);

    return (
        <FilterContext.Provider value={{ monthFilter: months, yearFilter: years, setYear, setMonth }}>
            {children}
        </FilterContext.Provider>
    );
}

export default FilterContextProvider;