import { FC } from "react";

import { Paper } from "@material-ui/core";
import { Chart, PieSeries, Tooltip, Title } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, Palette } from "@devexpress/dx-react-chart";

import schema from "../../common/consts/schema";
import CustomLegend from "../CustomLegend";

interface DistributionByGenderProps {
    genders: { gender: string, count: number }[];
}

const DistributionByGender: FC<DistributionByGenderProps> = ({ genders }) => {
    return (
        <Paper elevation={0} dir="rtl">
            <Chart
                data={genders}
            >
                <Palette scheme={schema} />
                <PieSeries
                    valueField="count"
                    argumentField="gender"
                    innerRadius={0.5}
                    outerRadius={0.7}
                />
                <Animation />
                <EventTracker />
                <Tooltip />
                <Title text="התפלגות שאלונים לפי מגדר" textComponent={({ text }) => <div className="title">{text}</div>} />
                <CustomLegend />
            </Chart>
        </Paper>
    );
}

export default DistributionByGender;