import { FC } from "react";
import { CombinedData, CombinedDateData } from "../common/contexts/GraphDataContext";

import '../scss/Tooltip.scss';

interface DataTooltipProps {
    data: CombinedData[];
}

const DataTooltip: FC<DataTooltipProps> = ({ data }) => {
    return (
        <div className="tooltip_container">
            {data.map(item =>
                <div className="tooltip_risklevel_container" key={item.riskLevel}>
                    <div className="risklevel_total">
                        {item.females + item.transgenders + item.others + item.males}
                    </div>
                    <div>
                        <div className="risklevel_name">
                            {item.riskLevel}
                        </div>
                        <div className="risklevel_counts">
                            <span>
                                גברים: {item.males}
                            </span>
                            <span>
                                נשים: {item.females}
                            </span>
                            <span>
                                טרסג'נדר: {item.transgenders}
                            </span>
                            <span>
                                אחר: {item.others}
                            </span>
                        </div>
                    </div>
                </div>)}
        </div>
    );
}

export default DataTooltip;