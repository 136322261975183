import { FC, useContext } from "react";

import { Chart, PieSeries, Title, Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Palette } from "@devexpress/dx-react-chart";

import CustomLegend from "../CustomLegend";
import schema from "../../common/consts/schema";
import { GraphDataContext } from "../../common/contexts/GraphDataContext";

const DistributionByAge: FC = () => {

    const { ageGroups } = useContext(GraphDataContext);
    const data = ageGroups.map(item => ({ ageGroup: item.ageGroup, count: parseInt(item.count) }));

    return (
        <Chart
            rootComponent={(props) => <div className="custom_material_ui_root" style={{ height: 500 }} {...props} />}
            data={data}
        >
            <Palette scheme={schema} />
            <PieSeries
                valueField="count"
                argumentField="ageGroup"
                innerRadius={0.5}
                outerRadius={0.7}
            />
            {/* <Animation /> */}
            <EventTracker />
            <Title text="התפלגות שאלונים לפי גיל" textComponent={({ text }) => <div className="distribution_by_age_title">{text}</div>} />
            <CustomLegend />
            <Tooltip />
        </Chart>
    );
}

export default DistributionByAge;