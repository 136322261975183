import { BrowserRouter, Switch } from "react-router-dom";
import { PrivateRoute, AuthProvider, PublicOnlyRoute } from "@hilma/auth";

import Login from "./scenes/Login";

import './App.css';
import Home from "./scenes/Home";


function App() {
    return (
        <BrowserRouter>
            <AuthProvider accessTokenCookie="kollkoolp">
                <Switch>
                    <PublicOnlyRoute path="/login" component={Login} componentName="login" />
                    <PrivateRoute redirectPath="/login" path="" component={Home} componentName="adminHome" />
                </Switch>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
