import { useState, useEffect, FC } from "react";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import WhiteCard from "../components/WhiteCard";

import "../scss/QuestionnaireDetails.scss";

const QuestionnaireDetails: FC = () => {
    const { grainId } = useParams<{ grainId: string }>();
    const history = useHistory();

    const [questions, setQuestions] = useState<{ question: string, answer: string }[]>([]);
    const [details, setDetails] = useState<{ startTime?: string, endTime?: string, riskLevel?: string } | null>(null);

    const getData = async () => {
        const url = `/api/statistics/single-questionnaire/${grainId}`;

        try {
            const { data } = await axios.get<{ questions: typeof questions, details: typeof details }>(url);

            setQuestions(data.questions);
            setDetails(data.details);
        } catch (err) {
            console.log(err);
        }
    }

    const handleGoBack = () => {
        history.goBack();
    }

    useEffect(() => { getData() }, []);

    return (
        <div className="questionnaire_details_container">
            <div className="title">
                <ArrowForwardIcon className="icon" onClick={handleGoBack} />
                &nbsp;
                פרטי שאלון
            </div>
            <div className="questions_container">
                <WhiteCard title="שאלות ותשובות" questions={questions} />
            </div>
            {
                details
                    ?
                    <WhiteCard
                        title="נתונים נוספים"
                        questions={[
                            {
                                question: "זמן כניסה:",
                                answer: details.startTime ? (new Date(details.startTime)).toLocaleTimeString() : ""
                            },
                            {
                                question: "זמן סיום",
                                answer: details.endTime ? (new Date(details.endTime)).toLocaleTimeString() : ""
                            },
                            {
                                question: "דירוג מסוכנות",
                                answer: details.riskLevel || ""
                            }
                        ]}
                    />
                    :
                    null
            }
        </div>
    );
}

export default QuestionnaireDetails;