import { FC, useContext } from "react";
import { InfoOutlined } from '@material-ui/icons';

import useReducedGraphData from "../../common/hooks/useReducedGraphData";
import { GraphDataContext, RiskLevelData, CombinedDateData, GraphDataContextType, CombinedData } from "../../common/contexts/GraphDataContext";
import DataTooltip from "../DataTooltip";

import "../../scss/DistributionByRiskLevel.scss";

const DistributionByRiskLevel: FC = () => {
    const reducedData = useReducedGraphData();

    const graphData = useContext(GraphDataContext);

    const sums = {
        high: 0,
        mid: 0,
        low: 0,
        critical: 0
    };

    for (const data of reducedData) {
        sums.high += data.high;
        sums.mid += data.mid;
        sums.low += data.low;
        sums.critical += data.critical;
    }

    type color = `rgb(${number}, ${number}, ${number})`;
    const data: {
        count: number;
        riskLevel: string;
        color: color;
        key: keyof typeof sums;
    }[] = [
            {
                count: sums.low,
                riskLevel: "רמת מסוכנות נמוכה",
                color: "rgb(33, 131, 128)",
                key: "low",
            },
            {
                count: sums.mid,
                riskLevel: "רמת מסוכנות בינונית",
                color: "rgb(255, 188, 66)",
                key: "mid",
            },
            {
                count: sums.high,
                riskLevel: "רמת מסוכנות גבוהה",
                color: "rgb(216, 17, 89)",
                key: "high",
            },
            {
                count: sums.critical,
                riskLevel: "רמת מסוכנות חריפה",
                color: "rgb(66, 165, 245)",
                key: "critical",
            },
        ];

    const tooltipData = generateTooltipData(graphData.riskLevels);

    return (
        <div className="distribution_by_risk_level_constainer">
            <div className="risk_level_title">
                התפלגות שאלונים לפי רמות מסוכנות
            </div>
            <div className="risk_level_data_container">
                {
                    data.map((item, index) => (
                        <div key={index} className="single_risk_level" style={{ borderRight: `3px solid ${item.color}` }}>
                            <div className="count">
                                {item.count}
                            </div>
                            <div className="risk_level_name_container">
                                <div className="risk_level_name">
                                    {item.riskLevel}
                                </div>
                                <InfoOutlined className="info_icon" />

                                <DataTooltip data={tooltipData[item.key as "high"] ? tooltipData[item.key as "high"] : []} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

const risks: Record<"חריפה" | "גבוהה" | "בינונית" | "נמוכה", "high" | "mid" | "low" | "critical"> = {
    "חריפה": "critical",
    "גבוהה": "high",
    "בינונית": "mid",
    "נמוכה": "low"
};

function generateTooltipData(riskLevels: GraphDataContextType["riskLevels"]): {
    high: CombinedData[];
    mid: CombinedData[];
    low: CombinedData[];
    critical: CombinedData[];
} {

    const reducedRiskLevelData: {
        high: RiskLevelData[],
        mid: RiskLevelData[],
        low: RiskLevelData[],
        critical: RiskLevelData[]
    } = {
        high: [],
        mid: [],
        low: [],
        critical: []
    };

    for (let data of riskLevels) {
        const riskLevelName = data.riskLevel.split(/[ ,]/).filter(item => item !== "")[2] as keyof typeof risks;
        const riskGroup = risks[riskLevelName];

        reducedRiskLevelData[riskGroup].push(data);
    }

    const tooltipData: {
        high: CombinedData[],
        mid: CombinedData[],
        low: CombinedData[],
        critical: CombinedData[]
    } = {
        high: [],
        mid: [],
        low: [],
        critical: []
    };
    let key: "high" | "mid" | "low" | "critical";
    for (key in reducedRiskLevelData) {
        const riskGroup: {
            [key: string]: {
                males: number;
                females: number;
                transgenders: number;
                others: number;
            }
        } = {};

        for (let riskItem of reducedRiskLevelData[key]) {

            let riskLevel = riskItem.riskLevel;
            if (!riskGroup[riskLevel]) {
                riskGroup[riskLevel] = {
                    males: 0,
                    females: 0,
                    transgenders: 0,
                    others: 0
                }
            }

            riskGroup[riskLevel].males += parseInt(riskItem.males);
            riskGroup[riskLevel].females += parseInt(riskItem.females);
            riskGroup[riskLevel].transgenders += parseInt(riskItem.transgenders);
            riskGroup[riskLevel].others += parseInt(riskItem.others);
        }

        tooltipData[key] = Object.keys(riskGroup).map(key => {
            return { riskLevel: key, ...riskGroup[key] }
        });
    }

    return tooltipData;
}

export default DistributionByRiskLevel;