import { FC, useContext } from "react";

import { EventTracker, Palette, } from "@devexpress/dx-react-chart";
import { Chart, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui";

import schema from "../../common/consts/schema";
import { GraphDataContext } from "../../common/contexts/GraphDataContext";

import "../../scss/DistributionByFinished.scss";

const DistributionByFinished: FC = () => {
    const { finishedCount, startedCount } = useContext(GraphDataContext);

    const notFinished = startedCount - finishedCount;
    const data = [
        {
            status: "finished",
            count: finishedCount
        },
        {
            status: "notFinished",
            count: notFinished
        }
    ];

    return (
        <div className="distribution_by_finished_container">
            <div className="legend_container">
                <div className="legend_item" style={{ borderRight: `4px solid ${schema[0]}` }}>
                    <div className="count" >
                        {finishedCount}
                    </div>
                    <div className="status">
                        שאלונים הסתיימו
                    </div>
                </div>
                <div className="legend_item" style={{ borderRight: `4px solid ${schema[1]}` }}>
                    <div className="count">
                        {notFinished}
                    </div>
                    <div className="status">
                        שאלונים נעצרו באמצע
                    </div>
                </div>
            </div>
            <div className="chart_container" >
                <Chart
                    rootComponent={({ children }) => <div className="custom_material_ui_root" style={{ height: "100%" }}  >{children}</div>}
                    data={data}
                >
                    <Palette scheme={schema} />
                    <PieSeries
                        valueField="count"
                        argumentField="status"
                    />
                    <EventTracker />
                    <Tooltip />
                </Chart>
            </div>
        </div>
    );
}

export default DistributionByFinished;