const schema = [
    "#2a8cb7",
    "#d81159",
    "#0A2042",
    "#ffbc42",
    "#218380",
    "#8F2D56",
    "#73D2DE"
];

export default schema;