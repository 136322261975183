import { FC } from "react";

import "../../scss/StatCard.scss";

interface StatCardProps {
    title: string;
    color: string;
    stat: string | number;
    hoverStat?: string | number;
}

const StatCard: FC<StatCardProps> = ({ stat, title, color, hoverStat }) => {
    return (
        <div className="card_container" style={{ backgroundColor: color }}>
            <div className="data_container">
                <div className={hoverStat !== undefined ? "disappear_data" : ""}>
                    {stat}
                </div>
                {
                    hoverStat !== undefined
                        ?
                        <div className="hover_data">
                            {hoverStat}
                        </div>
                        :
                        null
                }
            </div>
            <div className="sub_title">
                {title}
            </div>
        </div>
    );
}

export default StatCard;