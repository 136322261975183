import { FC, useEffect, useMemo, useState } from "react";
import axios from "axios";

import useFilterQuery from "../../common/hooks/useFilterQuery";
import StatCard from "./StatCard";
import NumberStatResponse from "../../common/interfaces/NumberStatResponse.interface";

import "../../scss/NumberStatCards.scss";


const NumberStatCards: FC = () => {
    const [questionnaireAverageTime, setQuestionnaireAverageTime] = useState(0);
    const [questionAverageTime, setQuestionAverageTime] = useState(0);
    const [wentBackCount, setWentBackCount] = useState(0);
    const [filledBySelf, setFilledBySelf] = useState<{ count: number, percent: number }>({ count: 0, percent: 0 });


    const filterQuery = useFilterQuery();

    const getAverageTimeData = async () => {
        try {
            // const res = await axios.get<{ avrageTime: string }>(`/api/statistics/avrage-time-to-complete${filterQuery}`);
            const res = await axios.get<NumberStatResponse>(`/api/statistics/number-statistics${filterQuery}`);
            setQuestionnaireAverageTime(parseInt(res.data.questionnaireAverageTime || "0"));
            setQuestionAverageTime(parseInt(res.data.questionAverageTime || "0"));
            setWentBackCount(parseInt(res.data.goBackCount || "0"));

            const self = res.data.forWhom.find(item => item.forWhom === "עבורי");

            if (self) {
                const selfCount = parseInt(self.count);
                const sum = res.data.forWhom.reduce((sum, item) => sum + parseInt(item.count), 0);
                const percent = Math.round(selfCount / sum * 100);
                setFilledBySelf({ count: selfCount, percent });
            } else {
                setFilledBySelf({ count: 0, percent: 0 })
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getAverageTimeData();
    }, [filterQuery]);


    const formattedQuestionnaire = useMemo(() => formatTime(questionnaireAverageTime), [questionnaireAverageTime]);
    const formattedQuestion = useMemo(() => formatTime(questionAverageTime), [questionAverageTime]);

    return (
        <div className="number_stat_card_container">
            <StatCard stat={wentBackCount} title="אנשים חזרו לשאלה קודמת" color="#D81159" />
            <StatCard stat={formattedQuestionnaire} title="זמן שהייה ממוצע בשאלון" color="#FFBC42" />
            <StatCard stat={formattedQuestion} title="זמן שהייה ממוצע בשאלה" color="#218380" />
            <StatCard stat={`${filledBySelf.percent}%`} hoverStat={filledBySelf.count} title="שאלונים מולאו על ידי המאובחן" color="#2A8CB7" />
        </div>
    );
}

function formatTime(seconds: number): string {
    seconds = Math.round(seconds);

    let min = Math.floor(seconds / 60);

    return `${min > 9 ? min : `0${min}`}:${(seconds % 60) > 9 ? (seconds % 60) : `0${(seconds % 60)}`}`;
}

export default NumberStatCards;