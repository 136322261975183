import React, { FC } from "react";

import { Paper } from "@material-ui/core";
import { Chart, BarSeries, ArgumentAxis, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import useReducedGraphData from "../../common/hooks/useReducedGraphData";
import DataTooltip from "../DataTooltip";

import schema from "../../common/consts/schema";

import "../../scss/Graph.scss";

const data: {
    riskLevel: string;
    color: string;
}[] = [
        {
            riskLevel: "רמת מסוכנות נמוכה",
            color: schema[4]
        },
        {
            riskLevel: "רמת מסוכנות בינונית",
            color: schema[3]
        },
        {
            riskLevel: "רמת מסוכנות גבוהה",
            color: schema[1]
        },
        {
            riskLevel: "רמת מסוכנות חריפה",
            color: schema[6]
        },
    ];

const RiskLevelGraph: FC = () => {
    const reducedData = useReducedGraphData();

    return (
        <div className="top_chart">
            <div className="top_chart_title_container">
                <span className="top_chart_title">
                    תוצאות השאלונים
                </span>
                <span className="top_chart_legend">
                    {data.map(item => {
                        return (
                            <React.Fragment key={item.riskLevel}>
                                <span>
                                    {item.riskLevel}
                                </span>
                                <span className="tick_mark" style={{ backgroundColor: item.color }}>
                                </span>
                            </React.Fragment>
                        )
                    })}
                </span>
            </div>
            <Paper elevation={0}>
                <Chart
                    data={reducedData}
                >
                    <ArgumentAxis />
                    <ValueAxis />

                    <BarSeries
                        barWidth={0.4}
                        name="low"
                        valueField="low"
                        argumentField="date"
                        color="#218380"
                    />
                    <BarSeries
                        barWidth={0.4}
                        name="mid"
                        valueField="mid"
                        argumentField="date"
                        color="#FFBC42"
                    />
                    <BarSeries
                        barWidth={0.4}
                        name="high"
                        valueField="high"
                        argumentField="date"
                        color="#D81159"
                    />
                    <BarSeries
                        barWidth={0.4}
                        name="critical"
                        valueField="critical"
                        argumentField="date"
                        color="#73D2DE"
                    />

                    <Animation />
                    <Stack />
                    <EventTracker />
                    <Tooltip contentComponent={
                        (props: Tooltip.ContentProps) => {
                            const risks: Record<"חריפה" | "גבוהה" | "בינונית" | "נמוכה", "high" | "mid" | "low" | "critical"> = {
                                "חריפה": "critical",
                                "גבוהה": "high",
                                "בינונית": "mid",
                                "נמוכה": "low"
                            };

                            const givenData = reducedData[props.targetItem.point].data.filter((item) => {
                                const riskLevel = item.riskLevel.split(/[ ,]/).filter(item => item !== "")[2] as keyof typeof risks;
                                return risks[riskLevel] === props.targetItem.series;
                            });
                            return <DataTooltip data={givenData} />
                        }
                    } />
                </Chart>
            </Paper>
        </div>
    );
}

export default RiskLevelGraph;


