import { FC } from "react";
import { Legend } from "@devexpress/dx-react-chart-material-ui";

import "../scss/CustomLegend.scss";

const CustomLegend: FC = () => {
    return (
        <Legend
            position="bottom"
            rootComponent={({ children }) => (
                <div className="custom_legend_root_container">
                    {children}
                </div>
            )}
            itemComponent={({ children }) => (
                <div className="custom_legend_item_container">
                    {children}
                </div>
            )
            }
        />
    );
}

export default CustomLegend;