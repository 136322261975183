const GENDERS: { text: string, value: string }[] = [
    {
        text: "הכל",
        value: "all"
    },
    {
        text: "זכר",
        value: "male"
    },
    {
        text: "נקבה",
        value: "female"
    },
    {
        text: "טרנסג'נדר",
        value: "transgender"
    },
    {
        text: "אחר",
        value: "other"
    }
];

export default GENDERS;