import { useLogout } from "@hilma/auth";
import { FC } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import FilterContextProvider from "../components/FilterContextProvider";
import Entries from "./Entries";
import QuestionnaireDetails from "./QuestionnaireDetails";
import Statistics from "./Statistics";

import "../scss/Home.scss";

const Home: FC = () => {
    const logout = useLogout();
    const handleLogout = () => {
        logout();
    }
    return (
        <div className="home_page_container">
            <div className="home_topbar">
                <div className="home_topbar_inner_container">
                    <a className={window.location.hash === "#/entries" ? "selected" : ""} href="#entries">כניסות</a>
                    <a className={window.location.hash === "#/statistics" ? "selected" : ""} href="#statistics">סטטיסטיקות</a>
                    <button onClick={handleLogout}>התנתק</button>
                </div>
            </div>
            <HashRouter>
                <FilterContextProvider>
                    <Switch>
                        <Route path="/entries" component={Entries} />
                        <Route path="/statistics" component={Statistics} />
                        <Route path="/questionnaire-details/:grainId" component={QuestionnaireDetails} />
                        <Route path="" component={Statistics} />
                    </Switch>
                </FilterContextProvider>
            </HashRouter>
        </div>
    );
}

export default Home;


