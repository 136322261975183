const TIMES: { text: string, value: string }[] = [
    {
        text: "הכל",
        value: "all"
    },
    {
        text: "היום",
        value: "today"
    },
    {
        text: "אתמול",
        value: "yesterday"
    },
    {
        text: "לפני שבוע",
        value: "week"
    },
    {
        text: "לפני חודש",
        value: "month"
    },
    {
        text: "טווח מותאם אישית",
        value: "range"
    }
];

export default TIMES;