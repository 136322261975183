import { FC } from "react";

import { Paper } from "@material-ui/core";
import { Chart, BarSeries, Tooltip, Title, ArgumentAxis, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from "@devexpress/dx-react-chart";

import schema from '../../common/consts/schema';

const getPath = (x: any, width: any, y: any, y1: any) => `
    M ${x} ${y1}
    L ${width + x} ${y1}
    L ${width + x} ${y}
    L ${x} ${y}
    Z
`;


const BarWithLabel: FC<BarSeries.PointProps> = ({ arg, barWidth, maxBarWidth, val, startVal, color, argument }) => {

    const width = maxBarWidth * barWidth;

    const fillColor =
        argument === 'ער"ן'
            ?
            schema[0]
            :
            argument === "סהר"
                ?
                schema[5]
                :
                color;
    return (
        <>
            <path d={getPath(arg - width / 2, width, val, startVal)} fill={fillColor} />
        </>
    );
};


interface LinkClicksProps {
    clicks: { organisation: string, count: number }[];
}

const LinkClicks: FC<LinkClicksProps> = ({ clicks }) => {
    return (
        <Paper elevation={0} dir="rtl">
            <Chart
                data={clicks}
            >
                <ArgumentAxis />
                <ValueAxis />
                <BarSeries
                    color={schema[3]}
                    name="ערן"
                    valueField="count"
                    argumentField="organisation"
                    pointComponent={BarWithLabel}
                    barWidth={0.6}
                />
                <Animation />
                <EventTracker />
                <Tooltip />
                <Title text="מעבר לאתר עמותה בסיום שאלון" textComponent={({ text }) => <div className="title">{text}</div>} />
            </Chart>
        </Paper>
    );
}

export default LinkClicks;