import { createContext } from "react";

export type SelectItem = {
    text: string;
    value: string;
}

export type FilterContextType = {
    monthFilter: SelectItem[];
    yearFilter: SelectItem[];
    setYear: (value: SelectItem[]) => void;
    setMonth: (value: SelectItem[]) => void;
}

export const FilterContext = createContext<FilterContextType>({ monthFilter: [], yearFilter: [], setYear: () => null, setMonth: () => null });