import { FC } from "react";

import DistributionByAge from "./DistributionByAge";
import DistributionByFinished from "./DistributionByFinished";
import DistributionByRiskLevel from "./DistributionByRiskLevel";

import "../../scss/MiddleSection.scss";

const MiddleSection: FC = () => {
    return (
        <div className="middle_section_container">
            <div className="middle_section_item finished_container">
                <DistributionByFinished />
            </div>
            <div className="middle_section_item age_container">
                <DistributionByAge />
            </div>
            <div className="middle_section_item risk_level_container">
                <DistributionByRiskLevel />
            </div>
        </div>
    );
}

export default MiddleSection;