import { FC, useMemo } from "react";
import LanguageType from "../../common/type/Language.type";

import "../../scss/DistributionByLanguage.scss";

const LANGUAGE_KEYS = {
    "he": { color: "#D81159", text: "עברית" },
    "en": { color: "#0A2042", text: "אנגלית" },
    "ar": { color: "#218380", text: "ערבית" },
    "am": { color: "#8F2D56", text: "אמהרית" },
    "ru": { color: "#FFBC42", text: "רוסית" }
};

interface DistributionByLanguageProps {
    languages: { language: LanguageType, count: number }[];
}

const DistributionByLanguage: FC<DistributionByLanguageProps> = ({ languages }) => {



    const maxLanguageCount = useMemo(() => {
        return Math.max(...languages.map(item => item.count));
    }, [languages]);


    return (
        <div className="distribution_by_language_container" dir="rtl">
            <div className="title">התפלגות שאלונים לפי שפות</div>
            <div className="language_stat_container">
                {languages.map((item) =>
                    <div className="language_container" key={item.language}>
                        <span className="text_container">
                            <span>{LANGUAGE_KEYS[item.language].text}</span>
                            <span>{item.count}</span>
                        </span>
                        <div className="count_bar">
                            <div className="bar" style={{ backgroundColor: LANGUAGE_KEYS[item.language].color, width: `${(100 * item.count) / maxLanguageCount}%` }} />
                        </div>
                    </div>)}
            </div>
        </div>
    );
}

export default DistributionByLanguage;


