
function getRange(time: string): [number, number] {
    const today = (new Date()).getTime();

    switch (time) {
        case "all":
            return [0, today];

        case "today":
            return [today, today];

        case "yesterday":
            const yesterday = (new Date(Date.now() - 24 * 60 * 60 * 1000)).getTime();
            return [yesterday, yesterday];

        case "week":
            return [(new Date(Date.now() - 2 * 7 * 24 * 60 * 60 * 1000)).getTime(), (new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).getTime()];

        case "month":
            const monthAgo = new Date();
            monthAgo.setMonth(monthAgo.getMonth() - 1);
            const twoMonthsAgo = new Date();
            twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

            return [twoMonthsAgo.getTime(), monthAgo.getTime()];

        default:
            return [0, 0];
    }
}

export default getRange;