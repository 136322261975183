import { FC } from "react";
import { useHistory } from "react-router-dom";

import "../scss/EntryTable.scss";

export interface header {
    title?: string;
    weight?: number;
}

export interface Entry {
    riskLevel: string | null;
    gender: string;
    startTime: string;
    endTime: string | null;
    grainId: string;
}

export interface EntryTableProps {
    dir?: "rtl" | "ltr";
    headers: header[];
    data: Entry[];
}

const EntryTable: FC<EntryTableProps> = (props) => {
    const { dir = "ltr", headers, data } = props;

    const history = useHistory();

    const handleClick = (grainId: string) => {
        history.push(`questionnaire-details/${grainId}`);
    }

    return (
        <div className="entry_center_table_containter">
            <div className={`entry_center_table_headers`} style={{ display: "flex" }} dir={dir}>
                {headers.map((header, index) => {
                    return (
                        <span style={{ flex: header.weight }} key={index}>
                            {header?.title}
                        </span>
                    );
                })}
            </div>
            <div className={`entry_center_table_content`} dir={dir}>
                {data.map((entry: Entry, index: number) => {
                    return (
                        <div className="entry_center_table_row_container" key={`${entry.gender + entry.riskLevel}${index}`}>
                            <div className="entry_center_table_row" style={{ display: "flex" }} onClick={() => handleClick(entry.grainId)}>
                                <span style={{ flex: headers[0].weight }}>
                                    {entry.gender}
                                </span>
                                <span style={{ flex: headers[1].weight }}>
                                    {entry.riskLevel}
                                </span>
                                <span style={{ flex: headers[2].weight }}>
                                    {(new Date(entry.startTime)).toLocaleTimeString()}
                                </span>
                                <span style={{ flex: headers[3].weight }}>
                                    {entry.endTime && (new Date(entry.endTime)).toLocaleTimeString()}
                                </span>
                                <span style={{ flex: headers[3].weight }}>

                                </span>
                            </div>
                        </div>);
                })}
            </div>
        </div>
    );
}

export default EntryTable;