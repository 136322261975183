import { useContext, useMemo } from "react";
import { CombinedDateData, GraphDataContext, ReducedRiskLevelData, RiskLevelData } from "../contexts/GraphDataContext";


const reduceData = (data: RiskLevelData[]): ReducedRiskLevelData[] => {
    const combinedDateData: CombinedDateData[] = data.map(item => {
        return {
            date: `${item.y}-${item.m}`,
            riskLevel: item.riskLevel,
            males: parseInt(item.males),
            females: parseInt(item.females),
            transgenders: parseInt(item.transgenders),
            others: parseInt(item.others)
        }
    });

    const risks: Record<"חריפה" | "גבוהה" | "בינונית" | "נמוכה", "high" | "mid" | "low" | "critical"> = {
        "חריפה": "critical",
        "גבוהה": "high",
        "בינונית": "mid",
        "נמוכה": "low"
    };

    const reducedRiskLevelData: ReducedRiskLevelData[] = [];

    for (let data of combinedDateData) {
        if (reducedRiskLevelData.length === 0 || reducedRiskLevelData[reducedRiskLevelData.length - 1].date !== data.date) {
            reducedRiskLevelData.push({
                date: data.date,
                high: 0,
                mid: 0,
                low: 0,
                critical: 0,
                data: []
            });
        }
        const riskLevelName = data.riskLevel.split(/[ ,]/).filter(item => item !== "")[2] as keyof typeof risks;
        const riskGroup = risks[riskLevelName];

        reducedRiskLevelData[reducedRiskLevelData.length - 1][riskGroup] += data.females + data.males + data.others + data.transgenders;
        reducedRiskLevelData[reducedRiskLevelData.length - 1].data.push(data);
    }
    
    return reducedRiskLevelData;
}

export default function useReducedGraphData(): ReducedRiskLevelData[] {
    const data = useContext(GraphDataContext);

    const graphData = useMemo(() => {
        return reduceData(data.riskLevels);
    }, [data]);

    return graphData;
}