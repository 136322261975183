const RISK_LEVELS: { text: string, value: number }[] = [
    {
        text: "הכל",
        value: 0
    },
    {
        text: "רמת מסוכנות נמוכה, אם בכלל",
        value: 1
    },
    {
        text: "רמת מסוכנות נמוכה, עם גורמי סיכון",
        value: 2
    },
    {
        text: "רמת מסוכנות בינונית בשל גורמי סיכון",
        value: 3
    },
    {
        text: "רמת  מסוכנות בינונית (בשל דיכאון/אובדנות)",
        value: 4
    },
    {
        text: "רמת מסוכנות גבוהה",
        value: 5
    },
    {
        text: "רמת מסוכנות חריפה ",
        value: 6
    },
    {
        text: "רמת מסוכנות חריפה עם סכנה מיידית",
        value: 7
    }
];

export default RISK_LEVELS;