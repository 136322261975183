import { FC, useContext, useEffect, useState } from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import axios from "axios";

import { MONTHS, YEARS } from "../common/consts/dates";
import { FilterContext } from "../common/contexts/FilterContext";
import { GraphDataContext, GraphDataContextType } from "../common/contexts/GraphDataContext";

import NumberStatCards from "../components/statistics/NumberStatCards";

import BottomSection from "../components/statistics/BottomSection";
import RiskLevelGraph from "../components/statistics/RiskLevelGraph";
import MiddleSection from "../components/statistics/MiddleSection";

import useFilterQuery from "../common/hooks/useFilterQuery";
import "../scss/Statistics.scss";
import DistributionByHour from "../components/statistics/DistributionByHour";

const Statistics: FC = () => {

    const [graphData, setGraphData] = useState<GraphDataContextType>({ ageGroups: [], riskLevels: [], finishedCount: 0, startedCount: 0 });

    const { monthFilter, yearFilter, setMonth, setYear } = useContext(FilterContext);

    const queryFilter = useFilterQuery();

    const handleYearChange = (e: React.ChangeEvent<{ value: unknown; }>) => {
        let values = e.target.value as string[];

        if (values.length <= 0) return;

        if (values[0] === "all" && values.length >= 2) {
            values = values.slice(1);
        }

        if (values[values.length - 1] === "all") {
            values = ["all"];
        }

        setYear(YEARS.filter(year => values.includes(year.value)));
    }

    const handleMonthChange = (e: React.ChangeEvent<{ value: unknown; }>) => {
        let values = e.target.value as string[];

        if (values.length <= 0) return;

        if (values[0] === "all" && values.length >= 2) {
            values = values.slice(1);
        }

        if (values[values.length - 1] === "all") {
            values = ["all"];
        }

        setMonth(MONTHS.filter(month => values.includes(month.value)));
    }

    const getData = async () => {
        try {
            const { data } = await axios.get<GraphDataContextType>(`/api/statistics/risklevel-age-finished${queryFilter}`);
            setGraphData(data);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getData();
    }, [queryFilter]);

    return (
        <div className="statistics_page_container">
            <div className="statistics_title">
                <h3>סטטיסטיקות</h3>
            </div>
            <div className="statistics_filter_container">
                <span>
                    מבט על
                </span>
                <Select
                    className="mui_select"
                    variant="outlined"
                    value={monthFilter.map(item => item.value)}
                    defaultValue={MONTHS[0]}
                    onChange={handleMonthChange}
                    multiple
                    renderValue={() => monthFilter.map(item => item.text).join(",")}
                >
                    {MONTHS.map(month => (
                        <MenuItem value={month.value} key={month.value}>
                            <Checkbox checked={monthFilter.includes(month)} />
                            <ListItemText primary={month.text} />
                        </MenuItem>)
                    )}
                </Select>

                <Select
                    className="mui_select"
                    value={yearFilter.map(item => item.value)}
                    variant="outlined"
                    onChange={handleYearChange}
                    multiple
                    renderValue={() => yearFilter.map(item => item.text).join(",")}>

                    {YEARS.map(year => (
                        <MenuItem value={year.value} key={year.value}>
                            <Checkbox checked={yearFilter.includes(year)} />
                            <ListItemText primary={year.text} />
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <GraphDataContext.Provider value={graphData}>
                <RiskLevelGraph />
                <MiddleSection />
                <NumberStatCards />
                <BottomSection />
                <DistributionByHour />
            </GraphDataContext.Provider>
        </div >
    );
}

export default Statistics;