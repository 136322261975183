export const MONTHS = [
    {
        text: "הכל",
        value: "all"
    },
    {
        text: "ינואר",
        value: "1"
    },
    {
        text: "פברואר",
        value: "2"
    },
    {
        text: "מרץ",
        value: "3"
    },
    {
        text: "אפריל",
        value: "4"
    },
    {
        text: "מאי",
        value: "5"
    },
    {
        text: "יוני",
        value: "6"
    },
    {
        text: "יולי",
        value: "7"
    },
    {
        text: "אוגוסט",
        value: "8"
    },
    {
        text: "ספטמבר",
        value: "9"
    }, {
        text: "אוקטובר",
        value: "10"
    }, {
        text: "נובמבר",
        value: "11"
    }, {
        text: "דצמבר",
        value: "12"
    }
];

export const MONTH_NAMES = MONTHS.map(item => item.text);

const cYear = (new Date()).getFullYear();
const years = Array(cYear - 2021 + 1).fill(2021).map((item: number, index: number) => {
    return { text: (item + index).toString(), value: (item + index).toString() }
});
years.unshift({
    text: "הכל",
    value: "all"
});

export const YEARS = years;