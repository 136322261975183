import { createContext } from "react";

export interface RiskLevelData {
    males: string;
    females: string;
    transgenders: string;
    others: string;
    m: number;
    y: number;
    riskLevel: string;
}
export interface CombinedDateData {
    date: string;
    riskLevel: string;
    males: number;
    females: number;
    transgenders: number;
    others: number;
}

export type CombinedData = Omit<CombinedDateData, "date">;

export interface ReducedRiskLevelData {
    date: string;
    high: number;
    mid: number;
    low: number;
    critical: number;
    data: CombinedDateData[];
}

export interface AgeGroup {
    ageGroup: string;
    count: string;
}

export interface GraphDataContextType {
    riskLevels: RiskLevelData[];
    ageGroups: AgeGroup[];
    startedCount: number;
    finishedCount: number;
}


export const GraphDataContext = createContext<GraphDataContextType>({
    riskLevels: [],
    ageGroups: [],
    finishedCount: 0,
    startedCount: 0
});