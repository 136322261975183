import { FC, useEffect, useState } from "react";
import axios from "axios";

import useFilterQuery from "../../common/hooks/useFilterQuery";
import DistributionByLanguage from "./DistributionByLanguage";
import LinkClicks from "./LinkClicks";
import DistributionByGender from "./DistributionByGender";
import LanguageType from "../../common/type/Language.type";

import "../../scss/BottomSection.scss";

interface StatisticsResponse {
    gender: { gender: string, count: string }[];
    language: { language: LanguageType, count: string }[];
    link: { organisation: string, count: string }[];
}


const BottomSection: FC = () => {
    const filter = useFilterQuery();

    const [distributionByGender, setDistributionByGender] = useState<{ gender: string, count: number }[]>([]);
    const [distributionByLanguage, setDistributionByLanguage] = useState<{ language: LanguageType, count: number }[]>([]);
    const [organizationLinkCount, setOrganizationLinkCount] = useState<{ organisation: string, count: number }[]>([]);


    const getStatistics = async () => {
        try {
            const { data } = await axios.get<StatisticsResponse>(`/api/statistics/gender-language-link${filter}`);
            setDistributionByGender(data.gender.map(item => ({ gender: item.gender, count: parseInt(item.count) })));
            setDistributionByLanguage(data.language.map(item => ({ language: item.language, count: parseInt(item.count) })));
            setOrganizationLinkCount(data.link.map(item => ({ organisation: item.organisation, count: parseInt(item.count) })));
        } catch (err) {
            console.error(err);
        }
    }

    const filterQuery = useFilterQuery();

    useEffect(() => {
        getStatistics();
    }, [filterQuery]);

    return (
        <div className="bottom_section_container">
            <div className="distribution_by_language bottom_section_item">
                <DistributionByLanguage languages={distributionByLanguage} />
            </div>
            <div className="organization_link_tranfers bottom_section_item">
                <LinkClicks clicks={organizationLinkCount} />
            </div>
            <div className="distribution_by_gender bottom_section_item">
                <DistributionByGender genders={distributionByGender} />
            </div>
        </div>
    );
}

export default BottomSection;


