import { FC, useState } from "react";
import { useHistory } from "react-router";
import { useLogin } from "@hilma/auth";

import "../scss/Login.scss";

const Login: FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();

    const login = useLogin();

    const onFormSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const res = await login("/api/auth-user/login", { username, password });
        if (res.success) {
            history.push("");
        }
    }

    return (
        <div className="login_page_container" >
            <div className="form_container">
                <form className="login_container">
                    <h1>סטטיסטיקות שאלון אובדנות</h1>
                    <div className="input_container">
                        <input type="text" placeholder="שם משתמש" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <input type="password" placeholder="סיסמא" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="button_container">
                        <button onClick={onFormSubmit}>התחבר</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;


