import { FC } from "react";

import "../scss/DateRangeFilter.scss";

interface DateRangeFilterProps {
    show: boolean;
    start: string;
    setStart: (value: string) => void;
    end: string;
    setEnd: (value: string) => void;
}

const DateRangeFilter: FC<DateRangeFilterProps> = ({ show, start, setStart, end, setEnd }) => {

    if (!show) {
        return null;
    }

    return (
        <div className="date_range_filter_outer_container">

            <div className="date_range_filter_container">
                <label htmlFor="start">מתאריך</label>
                <input id="start" type="date" value={start} onChange={e => setStart(e.target.value)} />

                <label htmlFor="end">עד תאריך</label>
                <input id="end" type="date" value={end} onChange={e => setEnd(e.target.value)} />
            </div>
        </div>
    );
}

export default DateRangeFilter;