import { FC, useEffect, useState } from "react";
import axios from "axios";

import { Paper } from "@material-ui/core";
import { Chart, BarSeries, ArgumentAxis, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker } from "@devexpress/dx-react-chart";

import useFilterQuery from "../../common/hooks/useFilterQuery";
import schema from "../../common/consts/schema";

import "../../scss/Graph.scss";

const DistributionByHour: FC = () => {
    const [data, setData] = useState<{ count: number, hour: string }[]>([]);

    const queryFilter = useFilterQuery();

    const getData = async () => {
        try {
            const res = await axios.get<{ count: string, hour: number }[]>(`/api/statistics/distribution-by-hour${queryFilter}`);
            const data = formatData(res.data);
            setData(data);
        } catch (err) {
            console.error(err);
        }

    }

    useEffect(() => {
        getData();
    }, [queryFilter]);

    return (
        <div className="top_chart">
            <div className="top_chart_title_container">
                <span className="top_chart_title">
                    שאלונים לפי שעה
                </span>
            </div>
            <Paper elevation={0}>
                <Chart
                    data={data}
                >
                    <ArgumentAxis />
                    <ValueAxis />

                    <BarSeries
                        valueField="count"
                        argumentField="hour"
                        color={schema[Math.floor(Math.random() * (schema.length - 1))]}
                        barWidth={0.5}
                    />

                    <Animation />
                    <EventTracker />
                    <Tooltip />
                </Chart>
            </Paper>
        </div>
    );
}

function formatData(resData: { count: string, hour: number }[]): { count: number, hour: string }[] {
    const data: { count: number, hour: string }[] = [];
    let resIndex = 0;
    for (let i = 0; i < 24; i++) {
        if (resIndex < resData.length && resData[resIndex].hour === i) {
            data[i] = {
                count: parseInt(resData[resIndex].count),
                hour: resData[resIndex].hour.toString()
            };
            resIndex++;
        } else {
            data[i] = {
                count: 0,
                hour: i.toString()
            }
        }
    }

    return data;
}

export default DistributionByHour;


