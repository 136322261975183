import { FC, useEffect, useState } from "react";
import axios from "axios";
import { IconButton } from "@material-ui/core";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";

import EntryTable, { header, Entry } from "../components/EntryTable";

import TIMES from "../common/consts/entriesTimes";
import GENDERS from "../common/consts/genders";
import RISK_LEVELS from "../common/consts/riskLevels";
import getRange from "../common/functions/getRange";

import "../scss/Entries.scss"
import DateRangeFilter from "../components/DateRangeFilter";

const headers: header[] = [
    {
        title: "מגדר",
        weight: 1
    },
    {
        title: "רמת סיכון",
        weight: 2
    },
    {
        title: "זמן התחלה",
        weight: 1
    },
    {
        title: "זמן סיום",
        weight: 1
    },
    {
        title: "",
        weight: 1
    }
];

const RANGE = TIMES[TIMES.length - 1].value;

function dateStringToNumber(dateStr: string): number {
    const date = new Date(dateStr);
    return date.getTime();
}

const Entries: FC = () => {

    const [data, setData] = useState<Entry[]>([]);
    const [count, setCount] = useState<number>(1);
    const [offset, setOffset] = useState<number>(0);

    const [gender, setGender] = useState("all");
    const [riskLevel, setRiskLevel] = useState(0);
    const [time, setTime] = useState("all");

    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const getData = async () => {
        if (time === RANGE && (start === "" || end === "")) return;

        const [startNum, endNum] = time === RANGE ? [dateStringToNumber(start), dateStringToNumber(end)] : getRange(time);

        const url = `/api/statistics/entries/${offset}?gender=${gender}&riskLevel=${riskLevel}&start=${startNum}&end=${endNum}`;
        try {
            const res = await axios.get<{ entriesByOffset: Entry[], count: number }>(url);
            setData(res.data.entriesByOffset);
            setCount(res.data.count);
        } catch (err) {
            console.error(err);
        }
    };

    const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setGender(e.target.value);
        setOffset(0);
    }

    const handleRiskLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRiskLevel(parseInt(e.target.value));
        setOffset(0);
    }

    const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTime(e.target.value);
        setOffset(0);
    }

    const handleStartChange = (value: string) => {
        setStart(value);
        if (start && end) {
            setOffset(0);
        }
    }


    const handleEndChange = (value: string) => {
        setEnd(value);
        if (start && end) {
            setOffset(0);
        }
    }


    useEffect(() => {
        getData();
    }, [offset, gender, riskLevel, time, start, end]);

    return (
        <div className="entries_page_container" dir="rtl">
            <div className="entries_title_container">
                כניסות
            </div>
            <div className="entries_filter_container">
                <div>
                    <select value={time} onChange={handleTimeChange}>
                        {TIMES.map(time => <option value={time.value} key={time.value}>{time.text}</option>)}
                    </select>
                    <DateRangeFilter show={time === RANGE} start={start} end={end} setStart={handleStartChange} setEnd={handleEndChange} />
                </div>
                <select value={gender} onChange={handleGenderChange}>
                    {GENDERS.map(gender => <option value={gender.value} key={gender.value}>{gender.text}</option>)}
                </select>
                <select value={riskLevel} onChange={handleRiskLevelChange}>
                    {RISK_LEVELS.map(riskLevel => <option value={riskLevel.value} key={riskLevel.value}>{riskLevel.text}</option>)}
                </select>
            </div>
            <div className="entries_table_container">
                <EntryTable headers={headers} data={data} dir="rtl" />
            </div>
            <div className="entries_pager_container">
                {
                    data.length
                        ?
                        <>
                            {
                                offset > 0 &&
                                <IconButton onClick={() => setOffset((prev) => prev - 7)}>
                                    <ArrowRight />
                                </IconButton>
                            }
                            <span>{`${offset + 1}-${offset + data.length} מתוך ${count}`}</span>
                            {
                                (offset + 7) < count &&
                                <IconButton onClick={() => setOffset((prev) => prev + 7)}>
                                    <ArrowLeft />
                                </IconButton>
                            }
                        </>
                        :
                        "לא נמצאו תוצאות"
                }
            </div>

        </div>
    );
}

export default Entries;